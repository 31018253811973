@tailwind base;
@tailwind components;

body {
  @apply bg-white dark:bg-[#1e1e1e] text-slate-800 dark:text-white;
}

select {
  @apply dark:bg-gray-800;
}

@tailwind utilities;
